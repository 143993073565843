import { SectionEditor } from './Ridingazua.SectionEditor';
import { Section, Course, CourseListConfiguration } from '../common/Ridingazua.Model';
import { WaypointImagesForChart } from './Ridingazua.WaypointImagesForChart';
import { ApplicationState, ApplicationEventListener, ApplicationEvent } from './Ridingazua.ApplicationState';
import { Facebook } from './Ridingazua.Facebook';
import { TaskManager } from './Ridingazua.TaskManager';
import { MapController } from './Ridingazua.MapController';
import { MapSettingsController } from './Ridingazua.MapSettingsController';
import { StorageController } from './Ridingazua.StorageController';
import { SummaryController } from './Ridingazua.SummaryController';
import { BottomToolsController } from './Ridingazua.BottomToolsController';
import { PlaceSearchController } from './Ridingazua.PlaceSearchController';
import { SelectedRangeController } from './Ridingazua.SelectedRangeController';
import { ElevationChartController } from './Ridingazua.ElevationChartController';
import { SectionListDialogController } from './Ridingazua.SectionListDialogController';
import { Statics } from '../common/Ridingazua.Statics';
import { KeyState } from './Ridingazua.KeyState';
import * as timeago from 'timeago.js';
import timeago_ko from 'timeago.js/lib/lang/ko';
import { DirectDirector, AutoRouteDirector } from './Ridingazua.Director';
import { SessionController } from './Ridingazua.SessionController';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { Resources } from './Ridingazua.Resources';
import { TopToolsController } from './Ridingazua.TopToolsController';
import { AdsController } from './Ridingazua.AdsController';
import { SaveDialogController } from './Ridingazua.SaveDialogController';
import { CourseListDialogController } from './Ridingazua.CourseListDialogController';
import { ImportantDialogController } from './Ridingazua.ImportantDialogController';
import { ReadmeDialogController } from './Ridingazua.ReadmeDialogController';
import { DownloadDialogController } from './Ridingazua.DownloadDialogController';
import LZString from 'lz-string';
import { MapType } from './Ridingazua.MapType';
import { DirectionController } from './Ridingazua.DirectionController';
import { KakaoRoadViewButtonController } from './Ridingazua.KakaoRoadViewButtonController';

// build:
// npx webpack --mode development --config ./front.webpack.config.js

// TODO
// ESC로 dialog 닫기
// 차트도 선택된 섹션만 진하게 보이도록
// 코스 해쉬 태그 기능
// 자동 웨이포인트(마일스톤, 오르막구간, 내리막 구간)
// 예상 평속 큐시트
// 조회수
// 도움말
// IE 차단
// webpack optimize (https://beomi.github.io/2017/11/29/JS-TreeShaking-with-Webpack/)

export class Application implements ApplicationEventListener {
    /**
     * Application이 시작되면서 설정되는 course data, lzString을 이용해 base64로 압축되어있다.
     */
    public encodedCourse?: string;

    /**
     * Application이 시작되면서 설정되는 errorMessage.
     */
    public errorMessage?: string;

    /**
     * Application이 시작되면서 바로 파일을 다운로드해야할 경우 설정되는 파일 유형.
     * 여기에 'tcx' 또는 'gpx' 값이 들어있다면, 바로 다운로드 페이지로 넘어가야한다.
     */
    public autoStartDownloadFileType: string;

    private constructor() {
        ApplicationState.addListener(this);
        ApplicationState.addListener(SessionController.instance);

        document.onkeydown = (event) => {
            this.onKeyDown(event);
        };

        document.onkeyup = (event) => {
            this.onKeyUp(event);
        };

        document.oncopy = (event) => {
            if (event.target instanceof HTMLBodyElement) {
                let section = ApplicationState.selectedSection;
                if (section) {
                    SectionListDialogController.writeSectionToClipboard(section);
                }
            }
        };

        document.onpaste = (event) => {
            if (event.target instanceof HTMLElement) {
                let target = event.target as Element;
                let nodeName = target.nodeName.toLowerCase();
                if (target.nodeType == 1 && (nodeName == 'textarea' || (nodeName == 'input' && /^(?:text|email|number|search|tel|url|password)$/i.test(target['type'])))) {
                    return;
                } else {
                    setTimeout(() => {
                        SectionListDialogController.pasteSectionFromClipboard();
                    }, 100);
                }
            }
        };

        window.onfocus = () => {
            SessionController.checkSession();
        };

        Statics.siteHost = new URL(window.location.href).host;

        WaypointImagesForChart.createInstance();
        ElevationChartController.getInstance();
        SelectedRangeController.createInstance();
        MapController.addListenerToApplicationState();
        TopToolsController.createInstance();
        KakaoRoadViewButtonController.createInstance();
        SummaryController.createInstance();
        MapSettingsController.createInstance();
        BottomToolsController.createInstance();
        AdsController.createInstance();

        this.setTaskManagerListener();
        this.setToastrOptions();
        this.setTimeagoLocale();

        ReadmeDialogController.showIfRequired();
        ImportantDialogController.showIfRequired();

        console.log('hooray! application is created!');
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        if (ApplicationState.isCourseChangedEvent(event)) {
            this.saveEditingCourseToStorage();

            if (event == ApplicationEvent.COURSE_LOADED || event == ApplicationEvent.COURSE_SAVED) {
                // do nothing
            } else {
                ApplicationState.isSaveRequired = true;
            }
        }

        switch (event) {
            case ApplicationEvent.READY_TO_EDIT:
                this.onReadyToEdit();
                break;

            case ApplicationEvent.READY_TO_FACEBOOK_SESSION:
                this.onReadyToFacebookSession();
                break;

            case ApplicationEvent.READY_TO_KAKAO_SESSION:
                this.onReadyToKakaoSession();
                break;

            case ApplicationEvent.COURSE_LOADED:
                SectionEditor.resetEditors();

                let course = ApplicationState.course;
                let selectedSection: Section | null;
                if (course.sections.length) {
                    selectedSection = course.sections[0];
                }
                ApplicationState.selectedSection = selectedSection;

                if (course.sections.length > 1) {
                    SectionListDialogController.show();
                }

                if (!course.isEmpty) {
                    setTimeout(() => {
                        MapController.setVisibleAllPoints(course.allPoints());
                    }, 0.1);
                }
                break;

            case ApplicationEvent.SECTION_ADDED:
                let newSection = arg as Section;
                SectionEditor.addNewEditor(newSection);
                ApplicationState.selectedSection = newSection;
                if (ApplicationState.course.sections.length > 1) {
                    SectionListDialogController.show();
                }
                break;

            case ApplicationEvent.SECTION_REMOVED:
                let removedSection = arg as Section;
                SectionEditor.removeEditor(removedSection.clientId);
                break;

            case ApplicationEvent.UPDATE_BOTTOM_ELEMENTS_LAYOUT:
                this.updateBottomElementsLayout();
                break;

            case ApplicationEvent.SAVE_EDITING_COURSE:
                this.saveEditingCourseToStorage();
                break;
        }
    }

    static _instance: Application;

    static get instance(): Application {
        if (!this._instance) {
            this._instance = new Application();
        }
        return this._instance;
    }

    initializeMap() {
        MapController.initializeMap();
    }

    get facebook(): Facebook {
        return ApplicationState.facebook;
    }

    set facebook(value: Facebook) {
        if (this.facebook === value) {
            return;
        }
        ApplicationState.facebook = value;
    }

    get kakao(): any {
        return ApplicationState.kakao;
    }

    set kakao(value: any) {
        if (this.kakao === value) {
            return;
        }
        ApplicationState.kakao = value;
    }

    set google(value: any) {
        if (this.google === value) {
            return;
        }
        ApplicationState.google = value;
    }

    get google(): any {
        return ApplicationState.google;
    }

    private onKeyDown(event: KeyboardEvent) {
        KeyState.update(event);

        let activeElement = document.activeElement;
        let activeElementTagName = activeElement?.tagName.toLowerCase();
        if (activeElementTagName == 'input') {
            if ((activeElement.getAttribute('type') || '').toLowerCase() == 'text') {
                return;
            }
        }

        if (activeElementTagName == 'textarea') {
            return;
        }

        let isShortcutKey = KeyState.metaKey || KeyState.ctrlKey;

        let key = event.key.toLowerCase();
        switch (key) {
            case 'escape':
            case 'esc':
                SelectedRangeController.selectedRange = null;
                ElevationChartController.cancelZoom();
                ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
                break;

            case 's':
                if (isShortcutKey) {
                    SaveDialogController.show();
                    event.preventDefault();
                    return false;
                }
                break;

            case 'o':
                if (isShortcutKey) {
                    let configuration = CourseListConfiguration.defaultConfiguration();
                    configuration.loadPublic = ApplicationState.user ? false : true;
                    CourseListDialogController.showWithConfiguration(configuration);
                    event.preventDefault();
                    return false;
                }
                break;

            case 'a':
                if (isShortcutKey) {
                    let toAutoRouteEnable = DirectionController.selectedDirector == DirectDirector.instance;
                    DirectionController.selectedDirector = toAutoRouteEnable ? AutoRouteDirector.instance : DirectDirector.instance;
                    event.preventDefault();
                    return false;
                }
                break;

            case 'z':
                if (isShortcutKey) {
                    if (event.shiftKey) {
                        TaskManager.redoTask();
                    } else {
                        TaskManager.undoTask();
                    }
                    event.preventDefault();
                    return false;
                }
                break;

            case 'y':
                if (isShortcutKey) {
                    TaskManager.redoTask();
                    event.preventDefault();
                    return false;
                }
                break;

            case 'm':
                let selectedMapType = MapController.selectedMapType;
                switch (selectedMapType) {
                    case MapType.OPEN_STREET_MAP:
                        selectedMapType = MapType.GOOGLE_ROADMAP;
                        break;
                    default:
                    case selectedMapType:
                        selectedMapType = MapType.OPEN_STREET_MAP;
                        break;
                }

                MapController.selectedMapType = selectedMapType;
                event.preventDefault;
                break;

            default:
                break;
        }
    }

    private onKeyUp(event: KeyboardEvent) {
        KeyState.update(event);
    }

    private setTaskManagerListener() {
        TaskManager.didTaskDeniedByLockedEditor = () => {
            SummaryController.getInstance().didTaskDeniedByLockedEditor();
        };
    }

    private setToastrOptions() {
        toastr.options.positionClass = 'toast-top-left';
        toastr.options.tapToDismiss = true;
        toastr.options.timeOut = 3000;
    }

    private setTimeagoLocale() {
        let language = navigator.language || 'en';

        if (language.toLowerCase().startsWith('ko')) {
            timeago.register(language, timeago_ko);
        }
    }

    /**
     * 편집 중인 코스를 로컬 스토리지에 저장한다.
     */
    private saveEditingCourseToStorage() {
        StorageController.set('editingCourse', JSON.stringify(ApplicationState.course.toJson()));
    }

    private onReadyToEdit() {
        this.showErrorMessageIfRequired();

        // 코스가 로드된 이후에 처리되어야하므로, 1초 지연시킨다.
        setTimeout(() => {
            this.showAutoDownloadIfRequired();
        }, 1000);

        let course = this.loadEncodedCourseIfRequired() || this.loadEditingCourseOrCreateNewCourseIfRequired();
        ApplicationState.course = course;
        ApplicationState.replaceWindowHistoryStateByCurrent();

        PlaceSearchController.createInstance();
        SessionController.checkInitializeSession();

        this.updateBottomElementsLayout();
    }

    private onReadyToFacebookSession() {
        SessionController.checkInitializeSession();
    }

    private onReadyToKakaoSession() {
        SessionController.checkInitializeSession();
    }

    private showErrorMessageIfRequired() {
        let errorMessage = this.errorMessage || '';
        if (!errorMessage.length) {
            return;
        }

        CommonDialogController.showConfirm(Resources.text.error, errorMessage, [
            {
                text: Resources.text.ok,
            },
        ]);
    }

    private loadEncodedCourseIfRequired(): Course | null {
        let encodedCourse = this.encodedCourse || '';
        if (!encodedCourse.length) {
            return null;
        }

        let courseJsonString = LZString.decompressFromBase64(encodedCourse);
        if (!courseJsonString) {
            return null;
        }

        let courseJson = JSON.parse(courseJsonString);
        if (!courseJson) {
            return null;
        }

        return Course.fromJson(courseJson);
    }

    private loadEditingCourseOrCreateNewCourseIfRequired(): Course {
        let course = this.loadEditingCourseFromStorage();

        if (!course) {
            course = new Course();
            course.sections.push(Section.create(ApplicationState.newSectionName(course)));
        }

        return course;
    }

    /**
     * 편집 중인 코스를 로컬 스토리지에서 불러온다.
     */
    private loadEditingCourseFromStorage(): Course {
        let courseJson = JSON.parse(StorageController.get('editingCourse'));

        if (courseJson) {
            let course = Course.fromJson(courseJson);

            if (course.allPoints().length < 2) {
                // 코스의 점이 하나라면, 없는 셈 친다.
                course = null;
            }

            return course;
        }

        return null;
    }

    private updateBottomElementsLayout() {
        let elevationChartController = ElevationChartController.getInstance();
        let chartHeight = elevationChartController.isShowing ? elevationChartController.getHeight() : 0;

        let divMap = document.getElementById('div-map');
        let divLeftBottom = document.getElementById('div-left-bottom');
        let divRightBottom = document.getElementById('div-right-bottom');
        if (chartHeight) {
            divMap.style.bottom = `${chartHeight}px`;
            divLeftBottom.style.bottom = `${chartHeight + 30}px`;
            divRightBottom.style.bottom = `${chartHeight + 20}px`;
        } else {
            divMap.style.bottom = '';
            divLeftBottom.style.bottom = '';
            divRightBottom.style.bottom = '';
        }
    }

    private showAutoDownloadIfRequired() {
        if (!this.autoStartDownloadFileType || !this.autoStartDownloadFileType.length) {
            return;
        }

        var fileType = this.autoStartDownloadFileType.toLowerCase();
        let allowedFileTypes = ['gpx', 'tcx'];

        if (!allowedFileTypes.includes(fileType)) {
            return;
        }

        DownloadDialogController.show();
        DownloadDialogController.startDownload(fileType, false);
    }
}
