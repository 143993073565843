import { User, Section, Course } from '../common/Ridingazua.Model';
import { Facebook } from './Ridingazua.Facebook';
import { Resources } from './Ridingazua.Resources';
import { Statics } from '../common/Ridingazua.Statics';
import { SectionEditor } from './Ridingazua.SectionEditor';
import { SessionController } from './Ridingazua.SessionController';
import { MapWrapper } from './Ridingazua.MapWrapper';
import { isNothing } from '../common/Ridingazua.Utility';

export class ApplicationState {
    static _map: MapWrapper;

    static set map(value: MapWrapper) {
        let isSetInitialMap = isNothing(this._map);
        let bounds = this.map?.bounds;
        this._map = value;
        if (isSetInitialMap) {
            this.checkReadyToEdit();
            this.checkReadyToFacebookSession();
            this.checkReadyToKakaoSession();
        } else {
            this.executeListeners(ApplicationEvent.SELECTED_BASE_MAP_CHANGED);
            this.executeListeners(ApplicationEvent.UPDATE_BOTTOM_ELEMENTS_LAYOUT);
            
            // 베이스지도가 바뀔 경우, bounds를 그대로 유지할 경우, 점점 줌아웃이 되는데, 이를 보정하기 위한 처리
            this.map.bounds = bounds.scale(0.5);
        }
    }

    static get map(): MapWrapper {
        return this._map;
    }

    static boundsSouthKorea: number[][] = [
        [37.73885, 125.090991],
        [38.842545, 128.56267],
        [37.686703, 131.199388],
        [35.248594, 131.155443],
        [35.032979, 129.375658],
        [33.545786, 127.10288],
        [32.781276, 127.227988],
        [32.739193, 124.725842],
        [37.086973, 124.225413],
    ];

    static _facebook: Facebook;
    static set facebook(value: Facebook) {
        this._facebook = value;
        this.checkReadyToFacebookSession();
    }
    static get facebook(): Facebook {
        return this._facebook;
    }

    static _kakao: any;
    static set kakao(value: any) {
        this._kakao = value;
        this.checkReadyToKakaoSession();
    }
    static get kakao(): any {
        return this._kakao;
    }

    static _google: any;
    static set google(value: any) {
        this._google = value;
        this.checkReadyToGoogleSession();
    }
    static get google(): any {
        return this._google;
    }

    private static didReadyToEdit = false;

    private static checkReadyToEdit() {
        if (!this.map) {
            return;
        }

        if (this.didReadyToEdit) {
            return;
        }

        this.executeListeners(ApplicationEvent.READY_TO_EDIT);
    }

    private static checkReadyToFacebookSession() {
        if (!this.map) {
            return;
        }
        if (!this.facebook) {
            return;
        }
        this.executeListeners(ApplicationEvent.READY_TO_FACEBOOK_SESSION);
    }

    private static checkReadyToKakaoSession() {
        if (!this.map) {
            return;
        }
        if (!this.kakao) {
            return;
        }
        this.executeListeners(ApplicationEvent.READY_TO_KAKAO_SESSION);
    }

    private static checkReadyToGoogleSession() {
        this.google.accounts.id.initialize(
            {
                client_id: '478837686042-8fa2n0au96gqme07jsdn6rnelp3ja1db.apps.googleusercontent.com',
                callback: this.handleGoogleCredentialResponse
            }
        );
    }

    private static handleGoogleCredentialResponse(response: any) {
        // console.log("Encoded JWT ID token: " + response.credential);
        SessionController.loginWithGoogle(response.credential);
    }

    static _user?: User;
    static set user(value: User | null) {
        let oldValue = this.user;

        if (!oldValue && !value) {
            // 이미 로그아웃이 되어있는 상태
            return;
        }

        this._user = value;
        if (this.user) {
            if (oldValue && oldValue.email == value.email) {
                // 현재 사용자에서 변경되지 않았으므로 이벤트는 발생시키지 않는다.
                return;
            }
            this.executeListeners(ApplicationEvent.LOGIN, this.user);
        } else {
            this.executeListeners(ApplicationEvent.LOGOUT, null);
        }
    }
    static get user(): User | null {
        return this._user;
    }

    private static _isSaveRequired = false;

    static get isSaveRequired(): boolean {
        return this._isSaveRequired;
    }

    static set isSaveRequired(value: boolean) {
        this._isSaveRequired = value;
        ApplicationState.executeListeners(ApplicationEvent.COURSE_SAVE_REQUIRED_CHANGED, this.isSaveRequired);
    }

    static doNotShowUnloadAlert = false;

    static initialize() {
        window.onresize = () => {
            this.executeListeners(ApplicationEvent.WINDOW_RESIZED, window);
        };

        window.onbeforeunload = () => {
            if (this.isSaveRequired && !this.course.isEmpty && !this.doNotShowUnloadAlert) {
                return Resources.text.confirm_message_for_lost;
            }
            return null;
        };
    }

    private static _course = new Course();
    static get course(): Course {
        return this._course;
    }
    static set course(value: Course) {
        if (!value) {
            // null은 할당할 수 없다.
            return;
        }

        // 섹션명이 없는 것은 만들어준다.
        this._course = value;
        for (let section of value.sections) {
            if (!section.name || !section.name.trim().length) {
                section.name = this.newSectionName(value);
            }
        }

        value.updateTotalValues();
        this.executeListeners(ApplicationEvent.COURSE_LOADED, this.course);

        // 반드시 ApplicationEvent.COURSE_LOADED 이벤트 처리 후에 변경해야한다.
        this.isSaveRequired = value.id ? false : true;

        // id가 존재하는 코스가 로드되었을 때는, 편집기를 잠금상태로 시작한다.
        if (value.id) {
            SectionEditor.isLocked = true;
        } else {
            SectionEditor.isLocked = false;
        }
    }

    static get sections(): Section[] {
        return this.course.sections;
    }

    static insertSection(section: Section, index: number) {
        this.sections.splice(index, 0, section);
        this.executeListeners(ApplicationEvent.SECTION_ADDED, section);
    }

    static pushSection(section: Section) {
        this.insertSection(section, this.sections.length);
    }

    static removeSection(section: Section) {
        let index = this.sections.indexOf(section);
        if (index < 0) {
            return;
        }
        this.sections.splice(index, 1);
        this.executeListeners(ApplicationEvent.SECTION_REMOVED, section);
    }

    static moveSection(section: Section, indexToMove: number) {
        let index = this.sections.indexOf(section);
        if (index < 0) {
            return;
        }
        this.sections.splice(index, 1);
        this.sections.splice(indexToMove, 0, section);
        this.executeListeners(ApplicationEvent.SECTIONS_CHANGED, this.sections);
    }

    private static _selectedSection: Section;

    static get selectedSection(): Section {
        return this._selectedSection;
    }

    static set selectedSection(value: Section) {
        this._selectedSection = value;
        this.executeListeners(ApplicationEvent.SELECTED_SECTION_CHANGED, this.selectedSection);
    }

    static replaceWindowHistoryStateBySavedCourse() {
        let course = this.course;
        if (!course || !course.id) {
            return;
        }

        document.title = course.titleToDocument;
        window.history.replaceState({ courseId: course.id }, document.title, Statics.courseUrlString(course.idOrPathName));
    }

    static replaceWindowHistoryStateToEditingCourse() {
        document.title = Resources.text.ridingazua_editor_title;
        window.history.replaceState({}, document.title, Statics.editorPath);
    }

    static replaceWindowHistoryStateByCurrent() {
        let course = this.course;
        
        if (!course || !course.id) {
            this.replaceWindowHistoryStateToEditingCourse();
        } else {
            this.replaceWindowHistoryStateBySavedCourse();
        }
    }

    private static listeners: ApplicationEventListener[] = [];

    static addListener(listener: ApplicationEventListener) {
        this.listeners.push(listener);
    }

    static removeListener(listener: ApplicationEventListener) {
        let index = this.listeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.listeners.splice(index, 1);
    }

    /**
     * 이벤트 리스너들을 호출한다. 이벤트가 발생한 것처럼 강제로 호출할 수도 있다.
     * @param event
     * @param arg
     */
    static executeListeners(event: ApplicationEvent, arg?: any) {
        // listener들을 호출하는 과정에서 this.listeners의 변경이 발생할 수 있으므로 복제하여 처리한다.
        let listeners: ApplicationEventListener[] = this.listeners.map((listener) => {
            return listener;
        });

        listeners.forEach((listener) => {
            listener.handleApplicationEvent(event, arg);
        });
    }

    static newSectionName(course: Course): string {
        let currentMaxSectionNumber = 0;
        let allTextResouces = Resources.allTextResources;
        course.sections.forEach((section) => {
            if (!section.name) {
                return;
            }

            for (let textResource of allTextResouces) {
                if (!section.name.toLowerCase().startsWith(textResource.section.toLowerCase())) {
                    continue;
                }

                let numberString = section.name.substring(textResource.section.length).trim();
                let number = parseInt(numberString);
                if (!isNaN(number) && (!currentMaxSectionNumber || number > currentMaxSectionNumber)) {
                    currentMaxSectionNumber = number;
                }
            }
        });

        return `${Resources.text.section} ${currentMaxSectionNumber + 1}`;
    }

    /**
     * 현재 편집중인 course 데이터가 변경되는 event인지 여부
     * @param event
     */
    static isCourseChangedEvent(event: ApplicationEvent): boolean {
        return [
            ApplicationEvent.COURSE_LOADED,
            ApplicationEvent.COURSE_SAVED,
            ApplicationEvent.COURSE_NAME_CHANGED,
            ApplicationEvent.SECTION_ADDED,
            ApplicationEvent.SECTION_REMOVED,
            ApplicationEvent.SECTION_CHANGED,
            ApplicationEvent.SECTION_NAME_CHANGED,
            ApplicationEvent.SECTIONS_CHANGED,
            ApplicationEvent.SELECTED_SECTION_CHANGED,
            ApplicationEvent.COURSE_ELEVATIONS_SMOOTHED,
            ApplicationEvent.COURSE_ELEVATIONS_RELOADED,
            ApplicationEvent.WAYPOINTS_CHANGED,
        ].includes(event);
    }
}

ApplicationState.initialize();

export interface ApplicationEventListener {
    handleApplicationEvent(event: ApplicationEvent, arg: any | null): void;
}

export enum ApplicationEvent {
    // 이 아래는 코스 편집과 관련된 이벤트들
    COURSE_LOADED = 1,
    COURSE_SAVED,
    COURSE_NAME_CHANGED,
    SECTION_ADDED,
    SECTION_REMOVED,
    SECTION_CHANGED,
    SECTION_NAME_CHANGED,
    SECTIONS_CHANGED,
    SELECTED_SECTION_CHANGED,
    COURSE_ELEVATIONS_SMOOTHED,
    COURSE_ELEVATIONS_RELOADED,
    WAYPOINTS_CHANGED,

    // 지도 편집기와 관련된 이벤트들
    SELECT_RANGE,
    DESELECT_RANGE,
    SELECT_MAP_SECTION,
    DESELECT_MAP_SECTION,
    SELECTED_DIRECTOR_CHANGED,
    SELECTED_MAP_TYPE_CHANGED,
    SELECTED_BASE_MAP_CHANGED,
    UPDATE_LINES_REQUESTED,
    SET_CURSOR,
    REMOVE_CURSOR,

    // 편집중인 코스와 관련 없는 이벤트들
    READY_TO_EDIT,
    READY_TO_FACEBOOK_SESSION,
    READY_TO_KAKAO_SESSION,
    LOGIN,
    LOGOUT,
    WINDOW_RESIZED,
    COURSE_SAVE_REQUIRED_CHANGED,
    POSITION_WATCH_STARTED,
    POSITION_RECEIVED,
    POSITION_FAILED,
    UPDATE_BOTTOM_ELEMENTS_LAYOUT,
    SAVE_EDITING_COURSE,
    EDITOR_LOCKED_CHANGED,
}
