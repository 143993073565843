import { isNothing } from '../common/Ridingazua.Utility';
import { HTMLUtility, Rect } from './Ridingazua.HTMLUtility';

export interface ChartConfiguration {
    minYValue?: number;
    maxYValue?: number;
    minXValue?: number;
    maxXValue?: number;
    yAxisValueGap?: number;
    xAxisValueGap?: number;

    xValuesForLabel?: () => (number[] | null | undefined);

    xAxisValueFormatter?(value: number): string;
    yAxisValueFormatter?(value: number): string;
    xValueFormatter?(value: number): string;
    yValueFormatter?(value: number): string;
    cursorTextFormatter?(x: number, y: number): string;

    onMouseMoveOverData?(x: number, y: number): void;
    onMouseOutOfChart?(): void;
    onMouseDown?(x: number, y: number, event: MouseEvent): void;
    onLeftClick?(x: number, y: number, event: MouseEvent): void;
    onRightClick?(x: number, y: number, event: MouseEvent): void;
    onZoomChanged?(isZoomed: boolean): void;

    minWidth?: number;
    forImage?: boolean;
    pixelRatio?: number;
    placeholderText?: string;
    paddingWidthForZoom?: number;
    selectionBackgroundColor?: string;
}

export interface ChartAxisProperties {
    minValue: number;
    maxValue: number;
    gap: number;
    dynamic: number;
}

export interface ChartRange {
    minXValue: number,
    maxXValue: number
}

export class Chart {
    readonly configuration: ChartConfiguration = {};

    private shapeLineAlpha = 0.7;

    private get fontSizeForAxisLabel(): number {
        return this.devicePixel(14);
    }

    private get fontForAxisLabel(): string {
        return `${this.fontSizeForAxisLabel}px/${this.fontSizeForAxisLabel}px Arial`;
    }

    private get fillStyleForAxisLabel(): string {
        return 'rgb(0, 0, 0)';
    }

    private get fontSizeForCursorDescription(): number {
        return this.devicePixel(10);
    }

    private get fontForCursorDescription(): string {
        return `${this.fontSizeForCursorDescription}px/${this.fontSizeForCursorDescription}px Arial`;
    }

    private get fillStyleForAccessoryLabelBox(): string {
        return 'rgba(221, 221, 221, 0.5)';
    }

    private get fillStyleForAccessoryLabel(): string {
        return 'rgb(0, 0, 0)';
    }

    private get fontSizeForAccessoryLabel(): number {
        return this.devicePixel(12);
    }

    private get fontForAccessoryLabel(): string {
        return `${this.fontSizeForAccessoryLabel}px/${this.fontSizeForAccessoryLabel}px Arial`;
    }

    private get heightForAccessoryImage(): number {
        return this.devicePixel(25);
    }

    private _selection?: ChartRange;

    get selection(): ChartRange | null {
        return this._selection;
    }

    set selection(value: ChartRange | null) {
        if (isNothing(value?.minXValue) || isNothing(value?.maxXValue)) {
            this._selection = null;
        } else {
            this._selection = value;
        }

        this.drawSelectedRanges();
    }

    private div: HTMLDivElement;
    readonly chartCanvas = document.createElement('canvas');
    private cursorCanvas = document.createElement('canvas');
    private selectionCanvas = document.createElement('canvas');

    get chartContext(): CanvasRenderingContext2D {
        return this.chartCanvas.getContext('2d', {
            desynchronized: false
        });
    }

    get cursorContext(): CanvasRenderingContext2D {
        return this.cursorCanvas.getContext('2d', {
            desynchronized: false
        });
    }

    get selectionContext(): CanvasRenderingContext2D {
        return this.selectionCanvas.getContext('2d', {
            desynchronized: false
        });
    }

    constructor(div: HTMLDivElement, configuration?: ChartConfiguration) {
        this.configuration = configuration || {};

        this.div = div;
        div.style.overflowY = 'hidden';

        let minWidth = this.configuration.minWidth;
        if (minWidth) {
            div.style.overflowX = 'auto';
        }

        let canvases = [this.chartCanvas, this.selectionCanvas, this.cursorCanvas];
        canvases.forEach(canvas => {
            canvas.style.position = 'absolute';
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            canvas.style.left = '0';

            if (minWidth) {
                canvas.style.minWidth = `${this.configuration.minWidth}px`;
            }

            div.appendChild(canvas);
        });

        if (configuration.forImage) {
            this.chartCanvas.style.position = 'relative'
            this.cursorCanvas.style.visibility = 'hidden';
            this.selectionCanvas.style.visibility = 'hidden';
        }

        div.oncontextmenu = () => {
            return false;
        };
        div.onmousedown = (event) => {
            this.onMouseDown(event);
        };
        div.onmouseup = (event) => {
            this.onMouseUp(event);
        };
        div.onmouseout = (event) => {
            this.onMouseOut(event);
        };
        div.onmousemove = (event) => {
            this.onMouseMove(event);
        };

        this.redrawChart();
    }

    private _originalData?: ChartData;

    get originalData(): ChartData {
        return this._originalData;
    }

    set originalData(value: ChartData) {
        this._originalData = value;
        if (this.isZoomed) {
            this.updateDataByZoomRange();
        }
        this.redrawChart();
    }

    private get data(): ChartData | undefined {
        if (this.isZoomed) {
            return this.zoomedData;
        }

        return this._originalData;
    }

    private get items(): ChartDataItem[] {
        if (this.isZoomed) {
            return this.zoomedData.items || [];
        }

        return this.data?.items || [];
    }

    private defaultAxisValueFormatter = (value: number): string => {
        return value.toFixed(0);
    }

    private defaultValueFormatter = (value: number): string => {
        return value.toFixed(1);
    }

    get xAxisValueFormatter(): ((value: number) => string) {
        return this.configuration.xAxisValueFormatter || this.defaultAxisValueFormatter;
    }

    get yAxisValueFormatter(): (value: number) => string {
        return this.configuration.yAxisValueFormatter || this.defaultAxisValueFormatter;
    }

    get xValueFormatter(): (value: number) => string {
        return this.configuration.xValueFormatter || this.defaultValueFormatter;
    };

    get yValueFormatter(): (value: number) => string {
        return this.configuration.xValueFormatter || this.defaultValueFormatter;
    };

    private devicePixel(px: number): number {
        let pixelRatio = this.configuration.pixelRatio || window.devicePixelRatio || 1;
        return px * pixelRatio;
    }

    /**
     * 차트를 다시 그린다.
     */
    redrawChart(redrawCount: number = 0) {
        if (redrawCount == 0) {
            this.minAccessoryBoundsY = null;
            this.requiredPaddingTopForAccessories = 0;
        }

        if (redrawCount > 1) {
            return;
        }

        this.clearCursor();

        this.chartCanvas.width = this.devicePixel(this.chartCanvas.clientWidth);
        this.chartCanvas.height = this.devicePixel(this.chartCanvas.clientHeight);

        this.clearChart();

        if (this.chartCanvas.width <= 0
            || this.chartCanvas.height <= 0
            || this.items.length == 0) {
            this.drawPlaceholder();
            return;
        }

        this.updateAxisProperties();
        this.drawLabelsForYAxis();
        this.drawLabelsForXAxis();

        // drawShapeAndLine을 drawXAxisLine, drawYAxisLine 보다 먼저해야 shape와 line이 axisLine을 가리는 것을 피할 수 있다.
        this.drawShapeAndLine();
        this.drawXAxisLine();
        this.drawYAxisLine();
        this.drawAccessories();
        this.drawSelectedRanges();

        // test 코드다
        this.drawRotatedText();

        let minAccessoryBoundsY = this.minAccessoryBoundsY;
        if (!isNothing(minAccessoryBoundsY) && minAccessoryBoundsY < 0) {
            this.redrawChart(redrawCount + 1);
        }
    }

    private clearChart() {
        let chartContext = this.chartContext;
        chartContext.fillStyle = 'rgb(255, 255, 255)';
        chartContext.clearRect(0, 0, this.chartCanvas.width, this.chartCanvas.height);
        chartContext.fillRect(0, 0, this.chartCanvas.width, this.chartCanvas.height);
        this.yAxisLabelsMaxX = null;
        this.xAxisLastLabelWidth = null;
    }

    public clearCursor() {
        this.cursorContext.clearRect(0, 0, this.cursorCanvas.width, this.cursorCanvas.height);
    }

    public clearSelection() {
        this.selectionContext.clearRect(0, 0, this.selectionCanvas.width, this.selectionCanvas.height);
    }

    private get padding(): number {
        return this.devicePixel(10);
    }

    private get additionalPaddingTop(): number {
        return this.requiredPaddingTopForAccessories;
    }

    /**
     * chartCanvas에서 값들이 그려지는 사각형 영역을 반환(axis labels 제외된 영역)
     */
    get boundsOfChart(): Rect {
        let x = (this.yAxisLabelsMaxX || 0) + this.devicePixel(10);
        let y = this.padding + this.additionalPaddingTop;
        let width = this.chartCanvas.width - this.padding - (this.xAxisLastLabelWidth || 0) / 2 - x;
        let chartMaxY = this.xAxisLabelsMinY - this.devicePixel(10);
        let height = chartMaxY - y;

        // 차트의 영역이 아무리 작아져도 높이가 음수가 되지는 않도록 하는 처리
        if (height < 0) {
            y = chartMaxY;
            height = chartMaxY - y;
        }

        return new Rect(x, y, width, height);
    }

    private yAxisLabelsMaxX: number;

    // y축에 그려진 label들의 중간(vertical middle) pixel 값 배열.
    private yAxisLabelMiddles: number[] = [];

    private get xAxisLabelsMinY(): number {
        return this.chartCanvas.height - this.padding - this.fontSizeForAxisLabel;
    }

    private xAxisLastLabelWidth: number;

    // x축에 그려진 label들의 중간(horizontal center) pixel 값 배열.
    private xAxisLabelCenters: number[] = [];

    yAxisProperties: ChartAxisProperties;
    xAxisProperties: ChartAxisProperties;

    private updateAxisProperties() {
        this.yAxisProperties = this.calculateAxisProperties(
            this.configuration.minYValue,
            this.configuration.maxYValue,
            this.configuration.yAxisValueGap,
            false,
            this.boundsOfChart.height
        );

        this.xAxisProperties = this.calculateAxisProperties(
            this.configuration.minXValue,
            this.configuration.maxXValue,
            this.configuration.xAxisValueGap,
            false,
            this.boundsOfChart.width
        );

        if (this.isZoomed) {
            this.yAxisProperties = this.calculateAxisProperties(
                this.configuration.minYValue,
                this.configuration.maxYValue,
                this.configuration.yAxisValueGap,
                false,
                this.boundsOfChart.height
            );

            let paddedZoomRange = this.paddedZoomRange;

            this.xAxisProperties = this.calculateAxisProperties(
                paddedZoomRange.minXValue,
                paddedZoomRange.maxXValue,
                null,
                false,
                this.boundsOfChart.width
            );
        }
    }

    private calculateAxisProperties(minValue: number | null, maxValue: number | null, gap: number | null, correctMaxValue: boolean, axisLengthPixel: number): ChartAxisProperties {
        if (isNothing(minValue)) {
            minValue = this.data.minY;
        }

        if (isNothing(maxValue)) {
            maxValue = this.data.maxY;
        }

        let dynamic = maxValue - minValue;

        if (isNothing(gap) || gap <= 0) {
            let numberOfLabels = Math.floor(axisLengthPixel / this.devicePixel(50)) + 1;
            if (numberOfLabels < 2) {
                numberOfLabels = 2;
            }

            gap = dynamic / (numberOfLabels - 1);
        }

        // max 값 보정
        if (correctMaxValue) {
            if ((dynamic % gap) !== 0) {
                dynamic = gap * (Math.floor(dynamic / gap) + 1);
                maxValue = minValue + dynamic;
            }
        }

        return {
            minValue: minValue,
            maxValue: maxValue,
            gap: gap,
            dynamic: dynamic
        };
    }

    private drawPlaceholder() {
        let text = this.configuration.placeholderText;
        let context = this.chartContext;
        context.fillStyle = this.fillStyleForAxisLabel;
        context.font = this.fontForAxisLabel;
        let width = context.measureText(text).width;
        let x = (this.chartCanvas.width - width) / 2;
        let y = (this.chartCanvas.height - this.fontSizeForAccessoryLabel) / 2;
        context.fillText(text, x, y + this.fontSizeForAccessoryLabel);
    }

    private drawLabelsForYAxis() {
        this.yAxisLabelMiddles = [];

        let boundsOfChart = this.boundsOfChart;

        let dynamic = this.yAxisProperties.dynamic;
        let gapOfValue = this.yAxisProperties.gap;
        let minValue = this.yAxisProperties.minValue;
        let maxValue = this.yAxisProperties.maxValue;

        let context = this.chartContext;
        context.fillStyle = this.fillStyleForAxisLabel;
        context.font = this.fontForAxisLabel;

        let valuesForLabel: number[] = [minValue];

        while (true) {
            let lastValueForLabel = valuesForLabel[valuesForLabel.length - 1];
            if (lastValueForLabel < maxValue) {
                let valueForLabel = Math.min(
                    lastValueForLabel + gapOfValue,
                    maxValue
                );

                valuesForLabel.push(valueForLabel);
            } else {
                break;
            }
        }

        let textsForLabel = valuesForLabel.map((value) => {
            return this.yAxisValueFormatter(value);
        });

        let textWidths = textsForLabel.map((text) => {
            return context.measureText(text).width;
        });

        let descSortedTextWidths = [...textWidths].sort((a, b) => {
            return b - a;
        });
        let maxTextWidth = descSortedTextWidths[0];

        let axisLengthPx = boundsOfChart.maxY - boundsOfChart.minY;
        let rectsForLabel: Rect[] = [];

        for (let i = 0; i < valuesForLabel.length; i++) {
            let value = valuesForLabel[i];
            let textWidth = textWidths[i];

            let ratio = (value - minValue) / dynamic;
            let middleYForText = boundsOfChart.maxY - (axisLengthPx * ratio);
            let x = this.padding + maxTextWidth - textWidth;
            let y = middleYForText - (this.fontSizeForAxisLabel / 2);

            rectsForLabel.push(new Rect(x, y, textWidth, this.fontSizeForAxisLabel))
            this.yAxisLabelMiddles.push(middleYForText);
        }

        let firstRect = rectsForLabel[0];
        let lastRect = rectsForLabel[rectsForLabel.length - 1];
        for (let i = 0; i < valuesForLabel.length; i++) {
            let text = textsForLabel[i];
            let rect = rectsForLabel[i];
            if (i == 0 || i >= rectsForLabel.length - 1) { // 첫번째거나 마지막이거나
                context.fillText(text, rect.minX, rect.maxY);
            } else {
                if (rect.intersect(firstRect) || rect.intersect(lastRect)) {
                    // 첫번째 또는 마지막과 겹칠 경우에는 글자 그리지 않는다.
                    // do not draw
                } else {
                    context.fillText(text, rect.minX, rect.maxY);
                }
            }
        }

        this.yAxisLabelsMaxX = this.padding + maxTextWidth;
    }

    private drawYAxisLine() {
        let boundsOfChart = this.boundsOfChart;

        let context = this.chartContext;
        context.lineWidth = 1;
        context.strokeStyle = 'rgb(0, 0, 0)';

        // 메인선
        context.beginPath();
        context.moveTo(boundsOfChart.minX, boundsOfChart.minY);
        context.lineTo(boundsOfChart.minX, boundsOfChart.maxY);
        context.stroke();

        context.beginPath();
        context.moveTo(boundsOfChart.maxX, boundsOfChart.minY);
        context.lineTo(boundsOfChart.maxX, boundsOfChart.maxY);
        context.stroke();

        // 보조 선들
        this.xAxisLabelCenters.forEach((x, index) => {
            context.strokeStyle = 'rgba(0, 0, 0, 0.3)';
            context.beginPath();
            context.moveTo(x, boundsOfChart.minY);
            context.lineTo(x, boundsOfChart.maxY);
            context.stroke();
        });
    }

    private drawLabelsForXAxis() {
        this.xAxisLabelCenters = [];

        let dynamic = this.xAxisProperties.dynamic;
        let gapOfValue = this.xAxisProperties.gap;
        let minValue = this.xAxisProperties.minValue;
        let maxValue = this.xAxisProperties.maxValue;

        let context = this.chartContext;
        context.fillStyle = this.fillStyleForAxisLabel;
        context.font = this.fontForAxisLabel;

        let lastLabelText = this.xAxisValueFormatter(this.xAxisProperties.maxValue);
        // xAxisLastLabelWidth는 boundsOfChart에 영향을 미치므로, 아래 두줄의 순서는 바뀌면 안된다.
        this.xAxisLastLabelWidth = context.measureText(lastLabelText).width;
        let boundsOfChart = this.boundsOfChart;

        let valuesForLabel: number[] = [minValue];
        while (true) {
            let valueForLabel = valuesForLabel[valuesForLabel.length - 1] + gapOfValue;
            if (valueForLabel > maxValue) {
                valueForLabel = maxValue;
            }
            valuesForLabel.push(valueForLabel);
            if (valueForLabel >= maxValue) {
                break;
            }
        }

        if (this.configuration.xValuesForLabel) {
            valuesForLabel = this.configuration.xValuesForLabel() || valuesForLabel;
        }

        let textsForLabel = valuesForLabel.map((value) => {
            return this.xAxisValueFormatter(value);
        });

        let textMetrics = textsForLabel.map((text) => {
            return context.measureText(text);
        });

        let axisLengthPx = boundsOfChart.maxX - boundsOfChart.minX;

        let rectsForLabel: Rect[] = [];

        for (let i = 0; i < valuesForLabel.length; i++) {
            let value = valuesForLabel[i];
            let metric = textMetrics[i];

            let ratio = (value - minValue) / dynamic;
            let ceterXForText = boundsOfChart.minX + (axisLengthPx * ratio);

            let yForText = this.chartCanvas.height - this.padding - this.devicePixel(5);
            let xForText = ceterXForText - (metric.width / 2);

            rectsForLabel.push(new Rect(xForText, yForText, metric.width, this.fontSizeForAxisLabel));
        }

        let firstRect = rectsForLabel[0];
        let lastRect = rectsForLabel[rectsForLabel.length - 1];
        for (let i = 0; i < valuesForLabel.length; i++) {
            let text = textsForLabel[i];
            let rect = rectsForLabel[i];
            if (i == 0 || i >= rectsForLabel.length - 1) { // 첫번째거나 마지막이거나
                context.fillText(text, rect.minX, rect.minY);
            } else {
                if (rect.intersect(firstRect) || rect.intersect(lastRect)) {
                    // 첫번째 또는 마지막과 겹칠 경우에는 글자 그리지 않는다.
                    // do not draw
                } else {
                    context.fillText(text, rect.minX, rect.minY);
                }
            }

            this.xAxisLabelCenters.push(rect.centerX);
        }
    }

    private drawXAxisLine() {
        let boundsOfChart = this.boundsOfChart;
        let context = this.chartContext;
        context.lineWidth = 1;
        context.strokeStyle = 'rgb(0, 0, 0)';

        // 메인 선
        context.beginPath();
        context.moveTo(boundsOfChart.minX, boundsOfChart.maxY);
        context.lineTo(boundsOfChart.maxX, boundsOfChart.maxY);
        context.stroke();

        // 보조 선들
        this.yAxisLabelMiddles.forEach((y, index) => {
            if (index == 0) {
                return;
            }

            context.strokeStyle = 'rgba(0, 0, 0, 0.3)';
            context.beginPath();
            context.moveTo(boundsOfChart.minX, y);
            context.lineTo(boundsOfChart.maxX, y);
            context.stroke();
        });
    }

    /**
     * 차트를 구성하는 점들
     */
    private chartVertices: ChartVertex[] = [];

    /**
     * accessory bounds 중 가장 minY 값이 작은 것
     */
    private minAccessoryBoundsY?: number;

    /**
     * accessory들을 그려내기 위해 필요한 top padding 값
     */
    private requiredPaddingTopForAccessories = 0;

    /**
     * 실제 값에 해당하는 선을 그리고, 모양에 대한 색칠을 한다.
     */
    private drawShapeAndLine() {
        if (this.items.length < 2) {
            return;
        }

        let context = this.chartContext;
        let boundsOfChart = this.boundsOfChart;
        let dBoundsX = boundsOfChart.maxX - boundsOfChart.minX;
        let dBoundsY = boundsOfChart.maxY - boundsOfChart.minY;
        let xAxisProperties = this.xAxisProperties;
        let yAxisProperties = this.yAxisProperties;

        let vertices = this.items.map((item) => {
            let ratioX = (item.x - xAxisProperties.minValue) / xAxisProperties.dynamic;
            let ratioY = (item.y - yAxisProperties.minValue) / yAxisProperties.dynamic;

            return new ChartVertex(
                item,
                boundsOfChart.minX + (ratioX * dBoundsX),
                boundsOfChart.maxY - (ratioY * dBoundsY)
            );
        });

        // draw shape
        for (let i = 1; i < vertices.length; i++) {
            let vertex1 = vertices[i - 1];
            let vertex2 = vertices[i];
            let dDataX = vertex2.source.x - vertex1.source.x;
            let dDataY = vertex2.source.y - vertex1.source.y;

            let slope = dDataY / dDataX;
            let red = 255;
            let green = 255;
            let blue = 255;
            let maxSlopeThreshould = 0.2;
            if (slope > maxSlopeThreshould) {
                red = 255;
                green = 0;
                blue = 0;
            } else if (slope < -maxSlopeThreshould) {
                red = 0;
                green = 0;
                blue = 255;
            } else if (slope > 0) {
                let ratio = slope / maxSlopeThreshould;
                red = 255;
                green = Math.floor(255 - 255 * ratio);
                blue = green;
            } else if (slope < 0) {
                let ratio = -slope / maxSlopeThreshould;
                blue = 255;
                red = Math.floor(255 - 255 * ratio);
                green = blue;
            } else {
                // do nothing
            }

            context.beginPath();
            context.fillStyle = `rgba(${red}, ${green}, ${blue}, ${this.shapeLineAlpha})`;
            context.moveTo(vertex1.pixelX, vertex1.pixelY);
            context.lineTo(vertex2.pixelX, vertex2.pixelY);
            context.lineTo(vertex2.pixelX, boundsOfChart.maxY);
            context.lineTo(vertex1.pixelX, boundsOfChart.maxY);
            context.closePath();
            context.fill();
        }

        // draw line
        context.beginPath();
        context.moveTo(vertices[0].pixelX, vertices[0].pixelY);
        for (let i = 1; i < vertices.length; i++) {
            let vertex = vertices[i];
            context.lineTo(vertex.pixelX, vertex.pixelY);
        }
        context.lineWidth = 3;
        context.strokeStyle = `rgba(102, 102, 102, ${this.shapeLineAlpha})`;
        context.stroke();

        this.chartVertices = vertices;
    }

    private drawAccessories() {
        let vertices = this.chartVertices;

        let verticesHasAccessory = vertices.filter((vertex) => {
            return vertex.source.accessory != null;
        });

        verticesHasAccessory.sort((a, b) => {
            return a.source.accessory.priority - b.source.accessory.priority;
        });

        this.measureAccessoryBounds();

        let minAccessoryY: number | null = null;

        for (let vertex of verticesHasAccessory) {
            // draw accessory
            this.drawMeasuredAccesory(vertex);

            let accessory = vertex.source.accessory;
            let imageRect = accessory.measuredImageRect;
            let labelBoxRect = accessory.measuredLabelBoxRect;

            if (isNothing(minAccessoryY) || (imageRect && imageRect.minY < minAccessoryY)) {
                minAccessoryY = imageRect.minY;
            }

            if (isNothing(minAccessoryY) || (labelBoxRect && labelBoxRect.minY < minAccessoryY)) {
                minAccessoryY = labelBoxRect.minY;
            }
        }

        this.minAccessoryBoundsY = minAccessoryY;
        if (!isNothing(minAccessoryY) && minAccessoryY < 0) {
            this.requiredPaddingTopForAccessories = (-minAccessoryY) + 20;
        }
    }

    private measureAccessoryBounds() {
        let context = this.chartContext;
        let boundsOfChart = this.boundsOfChart;

        let vertices = this.chartVertices;
        let verticesHasAccessory = vertices.filter((vertex) => {
            return vertex.source.accessory != null;
        });

        // reset measured rect
        verticesHasAccessory.forEach((vertex) => {
            vertex.source.accessory.measuredImageRect = null;
            vertex.source.accessory.measuredLabelBoxRect = null;
        });

        // measure image rect
        verticesHasAccessory.forEach((vertex) => {
            let accessory = vertex.source.accessory;
            let image = accessory.image;
            if (!image) {
                return;
            }

            let targetY = vertex.pixelY - this.devicePixel(5);

            let imageRatio = image.width / image.height;
            let imageHeight = this.heightForAccessoryImage;
            let imageWidth = imageRatio * imageHeight;
            let imageX = vertex.pixelX - imageWidth / 2;
            let imageY = targetY - imageHeight;
            accessory.measuredImageRect = new Rect(imageX, imageY, imageWidth, imageHeight);
        });

        // measure label rect
        verticesHasAccessory.forEach((vertex) => {
            let accessory = vertex.source.accessory;
            let label = accessory.label;
            if (!label) {
                return;
            }

            let targetY = vertex.pixelY - this.devicePixel(5);
            let imageRect = accessory.measuredImageRect;
            if (imageRect) {
                targetY = accessory.measuredImageRect.minY - this.devicePixel(3);
            }

            let padding = this.devicePixel(accessory.labelBoxPadding);
            let labelX = vertex.pixelX - this.devicePixel(5);
            let labelY = targetY - this.fontSizeForAccessoryLabel - padding * 2;

            context.fillStyle = this.fillStyleForAccessoryLabel;
            context.font = this.fontForAccessoryLabel;
            let textMetrics = context.measureText(label);
            let labelBoxWidth = textMetrics.width + padding * 2;
            let labelBoxHeight = this.fontSizeForAccessoryLabel + padding * 2;

            let labelBoxRect = new Rect(labelX, labelY, labelBoxWidth, labelBoxHeight);

            let overX = labelBoxRect.maxX - boundsOfChart.maxX;
            if (overX > 0) {
                labelBoxRect.x = vertex.pixelX - labelBoxRect.width + this.devicePixel(5);
            }

            accessory.measuredLabelBoxRect = labelBoxRect;
        });

        // avoid labels intersection
        verticesHasAccessory.forEach((vertex) => {
            let accessory = vertex.source.accessory;
            let labelBoxRect = accessory.measuredLabelBoxRect;
            if (!labelBoxRect) {
                return;
            }

            while (true) {
                // 이미 그려져 있는 accessory들과 label의 intersection이 없을 떄까지 순환
                let hasLabelIntersection = false;
                for (let otherVertex of verticesHasAccessory) {
                    if (otherVertex === vertex) {
                        continue;
                    }

                    let otherImageRect = otherVertex.source.accessory.measuredImageRect;
                    let otherLabelBoxlRect = otherVertex.source.accessory.measuredLabelBoxRect;
                    let intersection: Rect | null = null;
                    let intersectedRect: Rect | null = null;

                    if (otherImageRect) {
                        intersection = otherImageRect.intersect(labelBoxRect);
                        intersectedRect = otherImageRect;
                    }

                    if (!intersection && otherLabelBoxlRect) {
                        intersection = otherLabelBoxlRect.intersect(labelBoxRect);
                        intersectedRect = otherLabelBoxlRect;
                    }

                    if (intersection) {
                        hasLabelIntersection = true;
                        labelBoxRect.y = intersectedRect.minY - labelBoxRect.height - this.devicePixel(5);
                        break;
                    }
                }

                if (!hasLabelIntersection) {
                    break;
                }
            }
        });
    }

    /**
     * vertex에 accessory를 그린다.
     * @param vertex
     */
    private drawMeasuredAccesory(vertex: ChartVertex) {
        let context = this.chartContext;
        let boundsOfChart = this.boundsOfChart;

        let accessory = vertex.source.accessory;

        if (!accessory.isValid) {
            return;
        }

        let imageRect = accessory.measuredImageRect;
        let labelBoxRect = accessory.measuredLabelBoxRect;

        // draw dashed line for accessory
        context.save();
        context.beginPath();
        context.moveTo(vertex.pixelX, boundsOfChart.maxY);
        if (labelBoxRect) {
            context.lineTo(vertex.pixelX, labelBoxRect.maxY);
        } else if (imageRect) {
            context.lineTo(vertex.pixelX, imageRect.maxY);
        }
        context.lineWidth = 1;
        context.strokeStyle = '#000000';
        context.setLineDash([this.devicePixel(5), this.devicePixel(3)]);
        context.stroke();
        context.restore();

        // draw image
        if (imageRect) {
            context.drawImage(accessory.image, imageRect.minX, imageRect.minY, imageRect.width, imageRect.height);
        }

        // draw label box, label
        if (labelBoxRect) {
            context.fillStyle = this.fillStyleForAccessoryLabelBox;
            HTMLUtility.drawRoundRect(context, labelBoxRect.minX, labelBoxRect.minY, labelBoxRect.width, labelBoxRect.height, this.devicePixel(2), true, false);

            context.fillStyle = this.fillStyleForAccessoryLabel;
            context.font = this.fontForAccessoryLabel;

            // text는 y로 지정된 부분의 위로 그려진다. 따라서 bounds의 minY + fontHeight 값을 지정해줘야 원하는 bounds에 그려진다.
            let padding = accessory.labelBoxPadding;
            context.fillText(accessory.label, labelBoxRect.minX + padding, labelBoxRect.minY + this.fontSizeForAccessoryLabel);
        }
    }

    private _zoomRange?: ChartRange;

    get zoomRange(): ChartRange {
        return this._zoomRange;
    }

    set zoomRange(value: ChartRange) {
        this._zoomRange = value;
        this.updatePaddedZoomRange();
        this.isZoomed = !isNothing(value);
    }

    private paddedZoomRange?: ChartRange;

    private updatePaddedZoomRange() {
        if (!this.zoomRange) {
            this.paddedZoomRange = null;
            return;
        }

        let paddedValue = this.getValueXSizeOfWidthPixel(this.configuration.paddingWidthForZoom ?? 0);
        let minXValueForZoom = this.zoomRange.minXValue - paddedValue;
        let maxXValueForZoom = this.zoomRange.maxXValue + paddedValue;

        if (minXValueForZoom < this.originalData.minX) {
            minXValueForZoom = this.originalData.minX;
        }

        if (maxXValueForZoom > this.originalData.maxX) {
            maxXValueForZoom = this.originalData.maxX;
        }

        this.paddedZoomRange = {
            minXValue: minXValueForZoom,
            maxXValue: maxXValueForZoom
        };
    }

    private _isZoomed = false;

    get isZoomed(): boolean {
        return this._isZoomed && this.zoomRange != null;
    }

    set isZoomed(value: boolean) {
        // zoomRange가 설정되지 않으면 isZoomed는 무조건 false 이다.
        if (!this.zoomRange) {
            value = false;
        }

        this._isZoomed = value;
        this.updateDataByZoomRange();
        this.redrawChart();
        if (this.configuration.onZoomChanged) {
            this.configuration.onZoomChanged(value);
        }
    }

    private dragStartInfo?: ChartDragInfo;
    private _draggingRange?: ChartRange;

    private set draggingRange(value: ChartRange) {
        this._draggingRange = value;
        this.drawSelectedRanges();
    }

    private get draggingRange(): ChartRange {
        return this._draggingRange;
    }

    private onMouseDown(event: MouseEvent) {
        this.draggingRange = null;
        let rect = this.div.getBoundingClientRect();
        let x = event.clientX - rect.left + this.div.scrollLeft;
        let y = event.clientY - rect.top + this.div.scrollTop;
        let pixelX = this.devicePixel(x);
        let pixelY = this.devicePixel(y);
        let boundsOfChart = this.boundsOfChart;
        let data: [number, number];
        if (boundsOfChart.contains(pixelX, pixelY)) {
            data = this.getDataFromPixelX(pixelX);
        }

        if (data) {
            if (this.configuration.onMouseDown) {
                this.configuration.onMouseDown(data[0], data[1], event);
            }
            if (event.button == 0) {
                this.dragStartInfo = new ChartDragInfo(data[0], x);
            } else if (event.button == 2) {
                // right click만 처리한다. left click은 drag와 구별해야하므로, onMouseDown과 onMouseUp을 통해 판단한다.
                if (this.configuration.onRightClick && data) {
                    this.configuration.onRightClick(data[0], data[1], event);
                    event.cancelBubble = true;
                    event.stopPropagation();
                }
            }
        } else {
            if (this.configuration.onMouseDown) {
                this.configuration.onMouseDown(null, null, event);
            }
        }
    }

    private onMouseMove(event: MouseEvent) {
        let rect = this.div.getBoundingClientRect();
        let x = event.clientX - rect.left + this.div.scrollLeft;
        let pixelX = this.devicePixel(x);
        this.drawCursorOfPixelX(pixelX);

        let data = this.getDataFromPixelX(pixelX);
        if (data) {
            let xValue = data[0];
            let yValue = data[1];
            if (data && this.configuration.onMouseMoveOverData) {
                this.configuration.onMouseMoveOverData(xValue, yValue);
            }
        }

        // 아래는 dragging에 대한 처리 
        if (event.button == 0) {
            if (this.dragStartInfo && this.dragStartInfo.isDragging(x)) {
                if (!data) {
                    data = this.getDataFromPixelX(pixelX, true)
                }
                let xValue = data[0];
                this.draggingRange = {
                    minXValue: Math.min(this.dragStartInfo.startValueX, xValue),
                    maxXValue: Math.max(this.dragStartInfo.startValueX, xValue)
                }
            }
        }
    }

    private onMouseUp(event: MouseEvent) {
        let rect = this.div.getBoundingClientRect();
        let x = event.clientX - rect.left + this.div.scrollLeft;
        let y = event.clientY - rect.top + this.div.scrollTop;
        let pixelX = this.devicePixel(x);
        let pixelY = this.devicePixel(y);
        let boundsOfChart = this.boundsOfChart;

        let isDragging = this.dragStartInfo && this.dragStartInfo.isDragging(x);
        if (isDragging) {
            // drag ~ drop 으로 처리
            let data = this.getDataFromPixelX(pixelX, true);
            if (data) {
                let xValue = data[0];

                let minXValueForZoom = Math.min(this.dragStartInfo.startValueX, xValue);
                let maxXValueForZoom = Math.max(this.dragStartInfo.startValueX, xValue);

                if (minXValueForZoom < this.data.minX) {
                    minXValueForZoom = this.data.minX;
                }

                if (maxXValueForZoom > this.data.maxX) {
                    maxXValueForZoom = this.data.maxX;
                }

                this.zoomRange = {
                    minXValue: minXValueForZoom,
                    maxXValue: maxXValueForZoom,
                }
            }
        } else if (boundsOfChart.contains(pixelX, pixelY)) {
            // click으로 처리
            let data = this.getDataFromPixelX(pixelX);
            if (data) {
                let xValue = data[0];
                let yValue = data[1];
                if (event.button === 0 && this.configuration.onLeftClick) {
                    this.configuration.onLeftClick(xValue, yValue, event);
                } else if (event.button === 2 && this.configuration.onRightClick) {
                    this.configuration.onRightClick(xValue, yValue, event);
                }
            }
        }

        this.dragStartInfo = null;
        this.draggingRange = null;
    }

    /**
     * 줌된 영역을 보여주기 위한 data
     */
    private zoomedData?: ChartData;

    private updateDataByZoomRange() {
        if (!this.zoomRange) {
            this.zoomedData = null;
            return;
        }

        // _isZoomed 값을 잠시 false로 돌려놓는다.
        // dataByZoomRange을 만들 때, 원본 데이터(_data)를 참조하기 위함이다.
        // _isZoomed 값이 true로 되어있으면 일부 함수에서 dataByZoomRange를 참조하게 되므로 문제가 발생한다.
        let isZoomedForRollback = this._isZoomed;
        this._isZoomed = false;

        let paddedZoomRange = this.paddedZoomRange;
        let minXValueForZoom = paddedZoomRange.minXValue;
        let maxXValueForZoom = paddedZoomRange.maxXValue;

        let originalData = this._originalData;
        let originalItems = originalData.items || [];
        let itemsByRange: ChartDataItem[] = [];
        originalItems.forEach(item => {
            if (minXValueForZoom <= item.x && item.x <= maxXValueForZoom) {
                itemsByRange.push(item);
            }
        });

        itemsByRange.splice(0, 0, new ChartDataItem(minXValueForZoom, this.getValueYFromValueX(minXValueForZoom)));
        itemsByRange.push(new ChartDataItem(maxXValueForZoom, this.getValueYFromValueX(maxXValueForZoom)));
        this.zoomedData = new ChartData(itemsByRange);

        this._isZoomed = isZoomedForRollback;
    }

    /**
     * 차트상의 x 값에 해당하는 부분에 cursor를 표시한다.
     * @param valueX
     */
    public drawCursorOfDataX(valueX: number) {
        this.clearCursor();
        let pixelX = this.getPixelXFromValueX(valueX);
        this.drawCursorOfPixelX(pixelX);
    }

    /**
     * 차트상의 x pixel 값에 해당하는 부분에 cursor를 표시한다.
     * @param pixelX
     */
    private drawCursorOfPixelX(pixelX: number) {
        this.clearCursor();

        // chartCanvas와 완전히 겹치게 둔다.
        this.cursorCanvas.width = this.devicePixel(this.chartCanvas.clientWidth);
        this.cursorCanvas.height = this.devicePixel(this.chartCanvas.clientHeight);

        let bounds = this.boundsOfChart;
        let context = this.cursorContext;

        context.lineWidth = 1;
        context.strokeStyle = 'rbg(0, 0, 0)';

        if (pixelX < bounds.minX) {
            pixelX = bounds.minX;
        }

        if (pixelX > bounds.maxX) {
            pixelX = bounds.maxX;
        }

        let vertices = this.getVerticesFromPixelX(pixelX);
        if (!vertices) {
            return;
        }

        let xRatio = (pixelX - vertices[0].pixelX) / (vertices[1].pixelX - vertices[0].pixelX);
        let dVerticesY = vertices[1].pixelY - vertices[0].pixelY;
        let pixelY = vertices[0].pixelY + dVerticesY * xRatio;

        // horizontal line
        context.beginPath();
        context.moveTo(bounds.minX, pixelY);
        context.lineTo(bounds.maxX, pixelY);
        context.stroke();

        context.beginPath();
        context.moveTo(pixelX, bounds.minY);
        context.lineTo(pixelX, bounds.maxY);
        context.lineWidth = 1;
        context.strokeStyle = '#000000';
        context.stroke();

        // description text
        // measure text
        let cursorTextFormatter = this.configuration.cursorTextFormatter;
        if (!cursorTextFormatter) {
            return;
        }

        context.font = this.fontForCursorDescription;

        let data = this.getDataFromPixelX(pixelX);
        let dataX = data[0];
        let dataY = data[1];
        let text = cursorTextFormatter(dataX, dataY);

        let lineSpacing = this.devicePixel(1);
        let textSize = HTMLUtility.measureMutlineText(context, text, this.fontSizeForCursorDescription, lineSpacing);
        let boxPadding = this.devicePixel(3);
        let boxWidth = textSize.width + (boxPadding * 2);
        let boxHeight = textSize.height + (boxPadding * 2);

        let margin = this.devicePixel(5)
        let boxRect = new Rect(
            pixelX + margin,
            pixelY - margin - boxHeight,
            boxWidth,
            boxHeight
        );

        let boundsOfChart = this.boundsOfChart;

        if (boxRect.maxX > boundsOfChart.maxX) {
            boxRect.x = pixelX - margin - boxRect.width;
        }

        if (boxRect.minY < boundsOfChart.minY) {
            boxRect.y = pixelY + margin
        }

        context.fillStyle = 'rgba(0, 0, 0, 0.7)';
        HTMLUtility.drawRoundRect(this.cursorContext, boxRect.minX, boxRect.minY, boxRect.width, boxRect.height, this.devicePixel(2), true, false);

        context.fillStyle = 'rgb(255, 255, 255)';
        HTMLUtility.drawMutlineText(context, text, this.fontSizeForCursorDescription, lineSpacing, boxRect.minX + boxPadding, boxRect.minY + this.devicePixel(1));
    }

    private drawSelectedRanges() {
        this.clearSelection();

        // chartCanvas와 완전히 겹치게 둔다.
        this.selectionCanvas.width = this.devicePixel(this.chartCanvas.clientWidth);
        this.selectionCanvas.height = this.devicePixel(this.chartCanvas.clientHeight);

        let boundsOfChart = this.boundsOfChart;
        let context = this.selectionContext;

        let ranges: ChartRange[] = [];

        if (this.selection) {
            ranges.push(this.selection);
        }

        if (this.draggingRange) {
            ranges.push(this.draggingRange);
        }

        ranges.forEach(range => {
            let minPixelX = this.getPixelXFromValueX(range.minXValue);
            let maxPixelX = this.getPixelXFromValueX(range.maxXValue);
            context.fillStyle = this.configuration.selectionBackgroundColor || 'rgba(100, 100, 100, 0.5)';
            context.fillRect(minPixelX, boundsOfChart.minY, maxPixelX - minPixelX, boundsOfChart.height);
        });
    }

    /**
     * canvas 상의 pixel 좌표 x의 전, 후 vertex를 반환한다.
     * @param pixelX
     * @param fix true로 설정될 경우, pixelX 값이 차트 영역을 벗어날 경우, minX 또는 maxX에 대한 vertex가 대신 반환된다..
     * @returns fix가 true로 설정되어 min, max를 벗어난 곳에 대한 결과가 반환될 때는 같은 vertex가 두개가 반환된다.
     */
    private getVerticesFromPixelX(pixelX: number, fix: boolean = false): [ChartVertex, ChartVertex] | null {
        for (let i = 1; i < this.chartVertices.length; i++) {
            let vertex1 = this.chartVertices[i - 1];
            let vertex2 = this.chartVertices[i];
            if (vertex1.pixelX <= pixelX && pixelX <= vertex2.pixelX) {
                return [vertex1, vertex2];
            }
        }

        if (fix) {
            if (this.chartVertices.length > 0) {
                let firstVertex = this.chartVertices[0];
                let lastVertex = this.chartVertices[this.chartVertices.length - 1];
                if (pixelX <= firstVertex.pixelX) {
                    return [firstVertex, firstVertex];
                } else if (pixelX >= lastVertex.pixelX) {
                    return [lastVertex, lastVertex];
                }
            }
        }

        return null;
    }

    /**
     * canvas 상의 pixel 좌표 x를 data 상의 x 값으로 변환한다.
     * @param pixelX
     * @param fix true로 설정될 경우, pixelX 값이 차트 영역을 벗어날 경우, minX 또는 maxX에 대한 값을 대신 반환한다.
     */
    private getDataFromPixelX(pixelX: number, fix: boolean = false): [number, number] | null {
        let vertices = this.getVerticesFromPixelX(pixelX, fix);

        if (!vertices) {
            return null;
        }

        if (vertices[0] == vertices[1]) {
            return [
                vertices[0].source.x,
                vertices[0].source.y
            ];
        }

        let xRatio = (pixelX - vertices[0].pixelX) / (vertices[1].pixelX - vertices[0].pixelX);
        let dDataX = vertices[1].source.x - vertices[0].source.x;
        let dataX = vertices[0].source.x + dDataX * xRatio;
        let dDataY = vertices[1].source.y - vertices[0].source.y;
        let dataY = vertices[0].source.y + dDataY * xRatio;

        return [dataX, dataY];
    }

    /**
     * valueX canvas 상의 pixel x 값으로 변환한다.
     * @param valueX
     */
    getPixelXFromValueX(valueX: number): number {
        let boundsOfChart = this.boundsOfChart;
        let chartWidth = boundsOfChart.width;
        let ratio = (valueX - this.xAxisProperties.minValue) / this.xAxisProperties.dynamic;
        return this.boundsOfChart.minX + (chartWidth * ratio);
    }

    /**
     * valueY canvas 상의 pixel y 값으로 변환한다.
     * @param valueY 
     */
    getPixelYFromValueY(valueY: number): number {
        let boundsOfChart = this.boundsOfChart;
        let chartHeight = boundsOfChart.height;
        let ratio = (valueY - this.yAxisProperties.minValue) / this.yAxisProperties.dynamic;
        return this.boundsOfChart.maxY - (chartHeight * ratio);
    }

    /**
     * valueX 해당하는 valueY 값을 반환한다.
     * @param valueX
     */
    getValueYFromValueX(valueX: number): number {
        let items = this.items ?? [];
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (item.x === valueX) {
                return item.y;
            }

            if (valueX < item.x) {
                if (i === 0) {
                    return null;
                }

                let pItem = items[i - 1];
                let xRatio = (valueX - pItem.x) / (item.x - pItem.x);
                let dY = item.y - pItem.y;
                let y = pItem.y + (dY * xRatio);
                return y;
            }
        }

        return null;
    }

    /**
     * 가로 px 크기가 valueX로 변경시 어느정도 되는지를 반환
     */
    private getValueXSizeOfWidthPixel(px: number): number {
        let boundsOfChart = this.boundsOfChart;
        return ((this.originalData.maxX - this.originalData.minX) / boundsOfChart.width) * px;
    }

    private onMouseOut(event: MouseEvent) {
        if (this.dragStartInfo) {
            this.dragStartInfo = null;
            this.selection = null;
        }

        this.clearCursor();
        if (this.configuration.onMouseOutOfChart) {
            this.configuration.onMouseOutOfChart();
        }
    }

    private drawRotatedText() {
        let text = 'Rotated Text';
        let context = this.chartContext;
        context.save();
        context.rotate(-0.5 * Math.PI);
        context.fillStyle = this.fillStyleForAxisLabel;
        context.font = this.fontForAxisLabel;
        context.fillText(text, 100, 100);
        context.restore();
    }
}

export class ChartDataItem {
    x: number;
    y: number;
    accessory?: ChartDataItemAccessory;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export class ChartDataItemAccessory {
    image?: HTMLImageElement;
    label?: string;
    priority = 0;

    measuredImageRect?: Rect;
    measuredLabelBoxRect?: Rect;
    labelBoxPadding = 2;

    get isValid(): boolean {
        return this.image != null || this.label != null;
    }

    constructor(image: HTMLImageElement | null, label: string | null) {
        this.image = image;
        this.label = label;
    }
}

export class ChartData {
    private _items: ChartDataItem[];

    set items(items: ChartDataItem[]) {
        this._items = items;
        this.update();
    }

    get items(): ChartDataItem[] {
        return this._items;
    }

    minX: number;
    maxX: number;
    minY: number;
    maxY: number;

    get dX(): number {
        return this.maxX - this.minX;
    }

    get dY(): number {
        return this.maxY - this.minY;
    }

    constructor(items: ChartDataItem[]) {
        this.items = [...items].sort((a, b) => {
            return a.x - b.x;
        });
    }

    private update() {
        if (this.items.length == 0) {
            this.minX = 0;
            this.maxX = 0;
            this.minY = 0;
            this.maxY = 0;
            return;
        }

        this.minX = this.items[0].x;
        this.maxX = this.items[this.items.length - 1].x;
        let minY: number = null;
        let maxY: number = null;

        this.items.forEach((item) => {
            if (minY == null || item.y < minY) {
                minY = item.y;
            }

            if (maxY == null || item.y > maxY) {
                maxY = item.y;
            }
        });

        this.minY = minY;
        this.maxY = maxY;
    }
}

/**
 * 차트를 구성하는 점의 정보
 */
class ChartVertex {
    source: ChartDataItem;
    pixelX: number;
    pixelY: number;
    constructor(source: ChartDataItem, x: number, y: number) {
        this.source = source;
        this.pixelX = x;
        this.pixelY = y;
    }
}

class ChartDragInfo {
    startPixelX: number;
    startValueX: number;

    private minDragDistance = 5;

    constructor(startValueX: number, startPixelX: number) {
        this.startValueX = startValueX;
        this.startPixelX = startPixelX;
    }

    isDragging(pixelX: number): boolean {
        let distance = Math.abs(pixelX - this.startPixelX);
        return distance > this.minDragDistance;
    }
}